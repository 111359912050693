//      

const StyleLayer = require('../style_layer');
const FillExtrusionBucket = require('../../data/bucket/fill_extrusion_bucket');
const {multiPolygonIntersectsMultiPolygon} = require('../../util/intersection_tests');
const {translateDistance, translate} = require('../query_utils');

                                                                        
                                                        
                                                

class FillExtrusionStyleLayer extends StyleLayer {

    getPaintValue(name        , globalProperties                   , featureProperties                    ) {
        const value = super.getPaintValue(name, globalProperties, featureProperties);
        if (name === 'fill-extrusion-color' && value) {
            value[3] = 1;
        }
        return value;
    }

    createBucket(parameters                  ) {
        return new FillExtrusionBucket(parameters);
    }

    queryRadius()         {
        return translateDistance(this.paint['fill-extrusion-translate']);
    }

    queryIntersectsFeature(queryGeometry                     ,
                           feature                   ,
                           geometry                     ,
                           zoom        ,
                           bearing        ,
                           pixelsToTileUnits        )          {
        const translatedPolygon = translate(queryGeometry,
            this.getPaintValue('fill-extrusion-translate', {zoom}, feature.properties),
            this.getPaintValue('fill-extrusion-translate-anchor', {zoom}, feature.properties),
            bearing, pixelsToTileUnits);
        return multiPolygonIntersectsMultiPolygon(translatedPolygon, geometry);
    }

    has3DPass() {
        return this.paint['fill-extrusion-opacity'] !== 0 && this.layout['visibility'] !== 'none';
    }
}

module.exports = FillExtrusionStyleLayer;
